<template>
  <div>
    <!-- 顶部搜索表单 -->
    <my-search-form
      :foldButton="true"
      :formData="searchFormData"
      @updateFormData="updateFormData"
      @submitSearch="submitSearch"
    >
    </my-search-form>
    <!-- 背景容器 -->
    <div class="bg-container">
      <!-- 左侧菜单 -->
      <div
        class="left-menu"
        v-loading="resultLoading"
        element-loading-background="rgba( 230, 224, 209, 0.1)"
      >
        <div class="menu-header" v-if="searchInfoShow">
          <span style="font-weight: 1000; color: #f6864a; margin-right: 20px"
            >查询结果</span
          >
          <span style="margin-right: 10px"
            >{{ resultList.total | nullToNum }}订单{{
              resultList.buyer_count | nullToNum
            }}买家</span
          >
          <span style="color:#00bb00;">({{selectMsg}})</span>
        </div>
        <div v-if="searchInfoShow">

        
        <el-menu 
          class="menu-body"
          text-color="#000"
          active-text-color="#F68C1E"
        >
          <el-menu-item 
            v-for="(item, index) in resultList.data"
            :key="index"
            :index="item.transaction_id"
            @click="showInfo(item, item.transaction_id)"
          >
            <span style="color:#488b00" v-if="item.express_print_state=='1'">✔</span>
            <span style="color:#3da6e3" v-if="item.invoice_print_state=='1'">✔</span>
            <span slot="title" style="margin-left: 10px">{{ item.buyer_login_id }}</span>
          </el-menu-item>
        </el-menu>
        </div>
        <div class="menu-footer" v-if="searchInfoShow">
          <el-pagination 
            small
            layout="prev, pager, next"
            :page-size="resultList.per_page"
            :total="resultList.total"
            :hide-on-single-page="pageHide"
            @current-change="handleCurrentChange"
           
          >
          </el-pagination>
        </div>
      </div>
      <!-- 右侧主体内容 -->
      <div class="right-content">
        <!-- 未查询时的缺省 -->
        <div v-if="singleprint_nodata" class="noDataClass">
        <!-- <div v-if="Object.keys(orderInfo).length == 0 " class="noDataClass"> -->
          <div style="cursor: pointer;flex-direction: column;align-items: flex-start;" @click="textJump">
            <!-- <div>
              <p></p>
              <p>如何使用快手电子面单？</p>
            </div> -->
            <div>
              <p></p>
              <p>如何使用快手电子面单打印？</p>
            </div>
          </div>
        </div>
        <!-- component占位 -->
        <component
          class="content-component"
          :is="isShow"
          v-loading="orderLoading"
        ></component>
      </div>
    </div>
    <!-- 查询进度条的弹窗 -->
    <progressBarPopup v-if="progressBarPopup!==false" :data="progressBarPopup" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import mySearchForm from "@/UI/mySearchForm";
import index from "@/components/singleprint/index";
import ResultInfo from "@/components/singleprint/ResultInfo";
import progressBarPopup from "@/UI/progressBarPopup";

export default {
  components: {
    mySearchForm,
    index,
    ResultInfo,
    progressBarPopup,
  },
  data() {
    return {
      backgroundColor: '',
      searchInfoShow:false,
      selectMsg:'',
      showTitle:true
    }
  },
  computed: {
    ...mapState({
      isShow: (state) => state.singleprint.isShow,
      orderInfo: (state) =>state.singleprint.orderInfo,
      circularType: (state) => state.singleprint.circularType,
      searchFormData: (state) => state.singleprint.searchFormData,
      resultLoading: (state) => state.singleprint.resultLoading,
      resultList: (state) => state.singleprint.resultList,
      pageHide: (state) => state.singleprint.pageHide,
      orderLoading: (state) => state.singleprint.orderLoading,
      progressBarPopup:(state) => state.singleprint.progressBarPopup,
      singleprint_nodata: (state) => state.singleprint.singleprint_nodata,
    }),
  },
  methods: {
    // searchForm查询条件更新函数
    updateFormData(val) {
      console.log(val,'---val');
      this.searchFormData = val;
    },
    // 点击查询按钮函数
    submitSearch() {
      this.searchInfoShow=false
      this.searchFormData.pull_data = 1 // 标识是否拉取新数据
      this.$store.dispatch("singleprint/get_search_result").then(res=>{
        this.searchFormData.pull_data = 0 // 使用完成后还原
      });
      this.$store.commit("singleprint/SET_ISSHOW", "index");
      this.$store.commit("singleprint/SET_PAGENUM", 1);
      setTimeout(() => {
        this.searchInfoShow=true
      });
    },
    // 查看订单详情函数
    showInfo(item, transaction_id) {
      this.$store.commit("singleprint/SET_PRINT_ORDER_INFO", item);
      let itemList = JSON.parse(JSON.stringify(item))
      for(let i in itemList.order_sub) {
        itemList.order_sub[i].checkedType = true
        itemList.order_sub[i].inputType = false
        itemList.order_sub[i].inputValue = ''
      }
      this.$store.commit("singleprint/SET_ORDERINFO", itemList);
      let data={}
      data['orderId']=item.transaction_id;
      data['caid']=item.caid;
      // this.$store.dispatch('singleprint/decryptBuyer',data);
      this.$store.commit("singleprint/SET_ORDERID", transaction_id);
      this.$store.commit("singleprint/SET_ISSHOW", "ResultInfo");
      this.$store.commit("singleprint/SET_ORDER_LOADING", false);
      // this.$store.dispatch('singleprint/get_order_info');
    },
    // 换页函数
    handleCurrentChange(page) {
      this.$store.commit("singleprint/SET_ISSHOW", "index");
      this.$store.commit("singleprint/SET_PAGENUM", page);
      this.$store.dispatch("singleprint/get_search_result");
    },
    textJump() {
      let path = `${window.location.protocol}//${window.location.host}/#/usetutorial`
      window.open(path);
    },
  },
   updated() {
    if(this.searchFormData.status==0){
      this.selectMsg='全部'
    }
    if(this.searchFormData.status==1){
      this.selectMsg='待扣款'
    }
    if(this.searchFormData.status==2){
      this.selectMsg='待发货'
    }
    if(this.searchFormData.status==3){
      this.selectMsg='已发货'
    }
    if(this.searchFormData.status==4){
      this.selectMsg='已取消'
    }
  },
  created() {
    this.$store.commit("singleprint/save",{singleprint_nodata:true})
    this.$store.commit("singleprint/SET_ISSHOW", "index");
  },
};
</script>

<style lang="less" scoped>
.bg-container {
  min-height: 500px;
  background: linear-gradient(to bottom, #e6e0d0 0%, #ffffff 100%);
  padding: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
}
.left-menu {
  width: 300px;
  min-width: 250px;
  // max-height: 355px;
  font-size: 12px !important;
  overflow: hidden;
  background-color: transparent !important;
}
.right-content {
  width: 1500px;
  min-width: 600px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 0px;
  font-size: 12px;
  position: relative;
  .content-component {
    min-height: 500px;
  }
}
.menu-header {
  margin-bottom: 20px;
  margin-top: 30px;
  border-bottom: 1px solid #d6ccb3;
  padding-bottom: 10px;
}
.menu-footer {
  display: flex;
  justify-content: center;
  transform: translateX(-5px);
  padding: 10px 0;
}
/deep/ .el-menu {
  border-right: none;
  background-color: transparent;
}
/deep/ .el-menu-item {
  padding: 0 !important;
  position: relative;
}
/deep/ .el-menu .el-menu-item {
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px dashed #cfcfce;
}
/deep/ .el-menu .el-menu-item:hover {
  background-color: #dfd8c2;
}
/deep/ .el-menu-item.is-active {
  background-color: #dfd8c2 !important;
  border-bottom: 1.5px solid #f6864a;
  font-weight: 1000;
}
.status-list {
  float: right;
}
/deep/ .iconfont {
  font-size: 12px;
}
.circularClass {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 40px;
  
}

.noDataClass {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  
  div {
    display: flex;
    align-items: center;
    p:nth-child(1) {
      width: 5px;
      height: 5px;
      background-color: #f5821f;
      border-radius: 50%;
      margin-right: 10px;
    }
    p:nth-child(2) {
      color: #333;
      font-size: 16px;
      line-height: 16px;
    }
  }
  div:nth-child(2) {
    padding-top: 15px;
  }
}
</style>